import React, { useEffect } from 'react';
import Script from 'next/script';
import { observer } from 'mobx-react';

import { adobeLaunchGlobalUrl, adobeLaunchGlobalUrlSDK } from '~/global/global.constants';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { IPersonalizationEvent } from '~/personalization/Personalization.types';

export const AdobeTracking = observer(() => {
	const {
		featureTogglesModel: {
			isOn = () => false,
		} = {},
		personalizationStore = {},
	} = useGlobalContext();

	const handlePersonalizationDecision = (event: Event) => {
		const { detail } = event as CustomEvent<IPersonalizationEvent>;

		personalizationStore.setDecisions(detail);
	};

	useEffect(() => {
		window.addEventListener('personalization', event => handlePersonalizationDecision(event));
		return () => {
			window.removeEventListener('personalization', handlePersonalizationDecision);
		};
	}, []);

	if (isOn('ADOBE_TARGET_WEBSDK') && Boolean(adobeLaunchGlobalUrlSDK)) {
		return (
			<>
				<Script
					strategy="afterInteractive"
					src={adobeLaunchGlobalUrlSDK}
				/>
			</>
		);
	}

	if (!adobeLaunchGlobalUrl) {
		return null;
	}

	return (
		<>
			<Script
				strategy="afterInteractive"
				src={adobeLaunchGlobalUrl}
			/>
		</>
	);
});
